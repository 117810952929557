import { Link } from 'react-router-dom';
import './Footer.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faEnvelopeOpen, faMap, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';

const Footer = (props) => {
  return (
    <div className="Footer">
      <div className="wrapper">
        <Link to="/" className="brand">
          <span>Saga</span>
          <span>Store</span>
        </Link>

        <div className="information section">
          <h2>Πληροφορίες</h2>
          <Link to="/">Όροι Χρήσης</Link>
          <Link to="/">Τρόποι Αποστολής</Link>
          <Link to="/">Τρόποι Πληρωμής</Link>
          <Link to="/">Επιστροφές - Αλλαγές</Link>
          <Link to="/">Επικοινωνία</Link>
        </div>

        <div className="account section">
          <h2>Ο Λογαριασμός μου</h2>
          <Link to="/">Σύνδεση</Link>
          <Link to="/">Εγγραφή</Link>
          <Link to="/">Λίστα Αγαπημένων</Link>
          <Link to="/">Παραγγελίες</Link>
        </div>

        <div className="contact section">
          <h2>Επικοινωνία</h2>
          <div>
            <div className="icon"><FontAwesomeIcon icon={faMap} size="sm"></FontAwesomeIcon></div>
            <span>Παπαδιαμάντη 14-16,<br />Καλοχώρι 570 09,<br />Θεσσαλονίκη</span>
          </div>

          <div>
            <div className="icon"><FontAwesomeIcon icon={faPhoneAlt} size="sm"></FontAwesomeIcon></div>
            <a href="tel:00302311222974">+30 2311 222 974</a>
          </div>

          <div>
            <div className="icon"><FontAwesomeIcon icon={faEnvelopeOpen} size="sm"></FontAwesomeIcon></div>
            <a href="mailto:info@sagastore.gr">info@sagastore.gr</a>
          </div>
        </div>
      </div>

      <div className="copyright">Copyright &copy; 2024 Saga Store <small>All rights and wrongs reserved</small> - <small>Powered by <a href="https://digitalkeys.gr/digital-marketing">DigitalKeys</a></small></div>
    </div>
  );
};

export default Footer;
