import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Header.scss';
import { faHeartCircleCheck, faSearch, faShapes, faShoppingBag, faStar, faUserAlt, faUserAstronaut } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const Header = (props) => {
  return (
    <div className="Header">
      <div className="wrapper">
        <Link to="/" className="brand">
          <span>Saga</span>
          <span>Store</span>
        </Link>

        <div className="navigation">
          <Link to="/products/playmobil" className="link">Playmobil</Link>
          <Link to="/products/loutrina" className="link">Λούτρινα</Link>
        </div>

        <div className="right">
          <Link to="/" className="btn-toggle-search" aria-label="Search">
            <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
          </Link>

          <Link to="/" className="btn-view-wishlist" aria-label="Favourites">
            <FontAwesomeIcon icon={faHeartCircleCheck}></FontAwesomeIcon>
          </Link>

          <button className="button btn-toggle-cart" aria-label="Shopping Cart">
            <FontAwesomeIcon icon={faShoppingBag}></FontAwesomeIcon>
          </button>
          
          <button className="button btn-toggle-account-menu" aria-label="Account">
            <FontAwesomeIcon icon={faUserAlt}></FontAwesomeIcon>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
