import Banner from '../banner/Banner';
import './HomePage.scss';
import { useEffect, useState } from 'react';
import slide1Image from '../../assets/img/banner-image-1.png';
import slide2Image from '../../assets/img/banner-image-2.png';
import { Link } from 'react-router-dom';
import CategoryGrid from '../category-grid/CategoryGrid';
import createCountdown from '../../countdown';
import request from '../../request';

const HomePage = (props) => {
  const [exclusiveOfferProduct, setExclusiveOfferProduct] = useState(null);
  const [exclusiveOfferExpireDate, setExclusiveOfferExpireDate] = useState('');

  useEffect(() => {
    request.get(`exclusive-offer`).then((response) => {
      setExclusiveOfferProduct(response.data.product);
      setExclusiveOfferExpireDate(response.data.expire_date);

      setTimeout(() => {
        const countdownEls = document.querySelectorAll(".countdown")
        countdownEls.forEach(countdownEl => createCountdown(countdownEl))
      });
    });
  }, []);

  return (
    <div className="HomePage page">
      <Banner>
        <div className="slide">
          <img src={slide1Image} alt="Little Dutch Δίσκος Κοπής από Ξύλο για 2+ Ετών" />

          <div className="details">
            <span className="header">Μοναδική Προσφορά!</span>
            <span className="title">Little Dutch Δίσκος Κοπής από Ξύλο για 2+ Ετών</span>
            <div className="price">17<span>,99 €</span></div>
            <Link to="/">Δείτε το</Link>
          </div>
        </div>

        <div className="slide">
          <img src={slide2Image} alt="Little Dutch Ξύλινο Παιχνίδι Σετ Τσαγιού για 3+ Ετών" />

          <div className="details">
            <span className="header">Μοναδική Προσφορά!</span>
            <span className="title">Little Dutch Ξύλινο Παιχνίδι Σετ Τσαγιού για 3+ Ετών</span>
            <div className="price">18<span>,99 €</span></div>
            <Link to="/">Δείτε το</Link>
          </div>
        </div>
      </Banner>

      <div className="wrapper">
        <CategoryGrid
          className="desktop"
          categoryId="5"
          fetchDelay={100}
        ></CategoryGrid>

        <CategoryGrid
          className="desktop"
          categoryId="7"
          fetchDelay={600}
        ></CategoryGrid>

        <CategoryGrid
          className="desktop"
          categoryId="6"
          fetchDelay={1100}
        ></CategoryGrid>

        <CategoryGrid
          className="mobile"
          categoryId="5"
          type="slider"
          fetchDelay={0}
        ></CategoryGrid>

        <CategoryGrid
          className="mobile"
          categoryId="7"
          type="slider"
          fetchDelay={500}
        ></CategoryGrid>

        <CategoryGrid
          className="mobile"
          categoryId="6"
          type="slider"
          fetchDelay={1000}
        ></CategoryGrid>
      </div>

      <div className="exclusive-offer">
        {
          exclusiveOfferProduct && <div className="wrapper">
            <div className="header">
              <span className="title">Αποκλειστική προσφορά</span>
              <div className="countdown" data-target-date={exclusiveOfferExpireDate}></div>
            </div>

            <div className="details">
              <img src={`${request.origin}cdn/products/${exclusiveOfferProduct.image}`} alt={exclusiveOfferProduct.title} />

              <div>
                <h2>{exclusiveOfferProduct.title}</h2>
                <div>Από <span style={{ textDecoration: 'line-through' }}>{parseFloat(exclusiveOfferProduct.initial_price).toFixed(2).replace('.', ',')}&nbsp;€</span> μόνο {parseFloat(exclusiveOfferProduct.final_price).toFixed(2).replace('.', ',')}&nbsp;€</div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  );
}

export default HomePage;
