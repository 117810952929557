import { useEffect, useRef, useState } from 'react';
import './Banner.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

const Banner = (props) => {
  const [index, setIndex] = useState(1);

  const wrapperEl = useRef(null);

  useEffect(() => {
    let isDown = false;
    let startPoint = null;

    function onDown(e) {
      isDown = true;
      startPoint = e.x || e.changedTouches[0].clientX;

      wrapperEl.current.querySelector('.images-wrapper').classList.add('dragging');
    }

    function onMove(e) {
      if (isDown) {
        const x = e.x || e.changedTouches[0].clientX;
        const offset = x - startPoint;

        positionImages(offset);

        e.stopPropagation();
        e.preventDefault();
      }
    }

    function onUp(e) {
      const x = e.x || e.changedTouches[0].clientX;
      const offset = x - startPoint;

      isDown = false;
      startPoint = null;

      if (Math.abs(offset) > (wrapperEl.current.offsetWidth * 0.15)) {
        if (offset > 0) {
          previous(false);
        } else {
          next(false);
        }
      } else {
        positionImages();
      }

      wrapperEl.current.querySelector('.images-wrapper').classList.remove('dragging');
    }

    function onResize() {
      // reposition slides silently
      wrapperEl.current.querySelector('.images-wrapper').classList.add('dragging');
      positionImages();
      wrapperEl.current.querySelector('.images-wrapper').classList.remove('dragging');
    }

    wrapperEl.current.addEventListener('mousedown', onDown);
    wrapperEl.current.addEventListener('touchstart', onDown);
    wrapperEl.current.addEventListener('mousemove', onMove);
    wrapperEl.current.addEventListener('touchmove', onMove);
    wrapperEl.current.addEventListener('mouseup', onUp);
    wrapperEl.current.addEventListener('touchend', onUp);
    window.addEventListener('resize', onResize);

    return () => {
      if (wrapperEl.current) {
        wrapperEl.current.removeEventListener('mousedown', onDown);
        wrapperEl.current.removeEventListener('touchstart', onDown);
        wrapperEl.current.removeEventListener('mousemove', onMove);
        wrapperEl.current.removeEventListener('touchmove', onMove);
        wrapperEl.current.removeEventListener('mouseup', onUp);
        wrapperEl.current.removeEventListener('touchend', onUp);
      }
      
      window.removeEventListener('resize', onResize);
    };
  });

  useEffect(() => {
    positionImages();
  }, [index, props.children]);

  function positionImages(offset = 0) {
    const images = wrapperEl.current.querySelector('.images-wrapper').children;
    let buffer = -wrapperEl.current.offsetWidth;

    if (index === 1 && offset > 0) {
      return false;
    }

    if (index === images.length && offset < 0) {
      return false;
    }

    for (let i = (index - 1) - 1; i >= 0; i--) {
      const img = images[i];

      img.style.left = buffer + offset + 'px';

      buffer -= wrapperEl.current.offsetWidth;
    }

    buffer = 0;

    for (let i = (index - 1); i < images.length; i++) {
      const img = images[i];

      img.style.left = buffer + offset + 'px';

      buffer += wrapperEl.current.offsetWidth;
    }
  }

  function next(rewind = true) {
    const images = wrapperEl.current.querySelector('.images-wrapper').children;
    let newIndex = index + 1;

    if (newIndex > images.length) {
      if (rewind) {
        newIndex = 1;
      } else {
        newIndex = index;
      }
    }

    setIndex(newIndex);
  }

  function previous(rewind = true) {
    const images = wrapperEl.current.querySelector('.images-wrapper').children;
    let newIndex = index - 1;

    if (newIndex <= 0) {
      if (rewind) {
        newIndex = images.length;
      } else {
        newIndex = index;
      }
    }

    setIndex(newIndex);
  }

  return (
    <div className="Banner">
      <div
        className="wrapper"
        ref={wrapperEl}
      >
        <div className="images-wrapper">
          {props.children}
        </div>

        <div className="navigation-arrows">
          <button aria-label="Previous Slide">
            <FontAwesomeIcon
              icon={faAngleLeft}
              size="4x"
              onClick={previous}
              className={`${index === 1 ? 'hidden' : ''}`}
            ></FontAwesomeIcon>
          </button>

          <button aria-label="Next Slide">
            <FontAwesomeIcon
              icon={faAngleRight}
              size="4x"
              onClick={next}
              className={`${index === props.children.length ? 'hidden' : ''}`}
            ></FontAwesomeIcon>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Banner;
