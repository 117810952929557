import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './ProductCardSkeleton.scss';
import { faShoppingBag } from '@fortawesome/free-solid-svg-icons';

const ProductCardSkeleton = (props) => {
  return (
    <div className="ProductCardSkeleton">
      <div className="image"></div>
      <div className="title"></div>
      <div className="price"></div>
      <button className="btn-add-to-cart" aria-label="Add to Cart">
        <FontAwesomeIcon icon={faShoppingBag} size="lg"></FontAwesomeIcon>
        <span>Στο καλάθι</span>
      </button>
    </div>
  );
};

export default ProductCardSkeleton;
