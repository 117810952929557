import './App.scss';
import './classes.scss';
import './overrides.scss';
import Header from './components/header/Header';
import request from './request';
import HomePage from './components/home-page/HomePage';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Footer from './components/footer/Footer';

function App() {
  request.get(`/`).then((response) => {
    console.log(response.data);
  });

  return (
    <div className="App">
      <BrowserRouter>
        <Header></Header>

        <main>
          <Routes>
            <Route exact path="/" element={<HomePage />}></Route>
            <Route exact path="/products/:category" element={<HomePage />}></Route>
          </Routes>
        </main>

        <Footer></Footer>
      </BrowserRouter>
    </div>
  );
}

export default App;
