import './ProductCard.scss';
import request from '../../request';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingBag } from '@fortawesome/free-solid-svg-icons';

const ProductCard = (props) => {
  return (
    <div className="ProductCard">
      <Link to={`/products/${props.data.id}/${props.data.title}`} aria-label={props.data.title} >
        <div className="image"
          style={{ backgroundImage: `url(${`${request.origin}cdn/products/${props.data.image}`})` }}
        ></div>
      </Link>

      <h1 title={props.data.title}><Link to={`/products/${props.data.id}/${props.data.title}`}>{props.data.title}</Link></h1>
      
      <div className="price">
        {
          parseFloat(props.data.final_price) > 0
            ? <Fragment>
                <span className="initial">{parseFloat(props.data.initial_price).toFixed(2).replace('.', ',')}&nbsp;€</span>
                <span className="final">{parseFloat(props.data.final_price).toFixed(2).replace('.', ',')}&nbsp;€</span>
              </Fragment>
            : <span>{parseFloat(props.data.initial_price).toFixed(2).replace('.', ',')}&nbsp;€</span>
        }
      </div>

      <button className="btn-add-to-cart" aria-label="Add to Cart">
        <FontAwesomeIcon icon={faShoppingBag} size="lg"></FontAwesomeIcon>
        <span>Στο καλάθι</span>
      </button>
    </div>
  );
};

export default ProductCard;