import axios from 'axios';

let baseURL = 'http://localhost/sagastore-backend/api/';
let origin = 'http://localhost/sagastore-backend/';

if (process.env.NODE_ENV === 'production') {
  baseURL = 'https://sagastore.gr/api/';
  origin = 'https://sagastore.gr/';
}

const axiosInstance = axios.create({
  baseURL,
  withCredentials: true,
});

const request = {
  origin,
  baseURL,
  axiosInstance,
  // throttling: 1500,

  get(url, params) {
    let resolveFn, rejectFn;
    
    const getRequestPromise = new Promise((resolve, reject) => {
      resolveFn = resolve;
      rejectFn = reject;
    });

    axiosInstance.get(url, params)
      .then((response) => {
        if (this.throttling > 0) {
          setTimeout(() => {
            resolveFn(response);
          }, this.throttling);
        } else {
          resolveFn(response);
        }
      })
      .catch((err) => {
        if (this.throttling > 0) {
          setTimeout(() => {
            rejectFn(err);
          }, this.throttling);
        } else {
          rejectFn(err);
        }
      });

    return getRequestPromise;
  },

  post(url, params) {
    let resolveFn, rejectFn;
    
    const postRequestPromise = new Promise((resolve, reject) => {
      resolveFn = resolve;
      rejectFn = reject;
    });

    axiosInstance.post(url, params)
      .then((response) => {
        if (this.throttling > 0) {
          setTimeout(() => {
            resolveFn(response);
          }, this.throttling);
        } else {
          resolveFn(response);
        }
      })
      .catch((err) => {
        if (this.throttling > 0) {
          setTimeout(() => {
            rejectFn(err);
          }, this.throttling);
        } else {
          rejectFn(err);
        }
      });

    return postRequestPromise;
  },
};

export default request;
