import { useEffect, useState } from 'react';
import './CategoryGrid.scss';
import request from '../../request';
import ProductCard from '../product-card/ProductCard';
import ProductCardSkeleton from '../product-card-skeleton/ProductCardSkeleton';
import Banner from '../banner/Banner';

const CategoryGrid = (props) => {
  const [products, setProducts] = useState([]);
  const [categoryName, setCategoryName] = useState('');
  const [fetchingProducts, setFetchingProducts] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      request.get(`categories/${props.categoryId}/shuffled`).then((response) => {
        setProducts(response.data.products);
        setCategoryName(response.data.category_name);
        setFetchingProducts(false);
      }).catch((err) => {
        setFetchingProducts(false);
      });
    }, props.fetchDelay || 0);
  }, [props.categoryId]);

  return (
    <div className={`CategoryGrid${props.className ? ' ' + props.className : ''}`}>
      {!fetchingProducts && <h2 className="title">{categoryName}</h2>}
      {fetchingProducts && <h2 className="title-skeleton"></h2>}

      {
        !fetchingProducts && props.type !== 'slider' && <div className="product-list">
          {
            products.map((product, i) => {
              return (
                <ProductCard
                  key={i}
                  data={product}
                ></ProductCard>
              );
            })
          }
        </div>
      }

      {
        !fetchingProducts && props.type === 'slider' && <Banner>
          {
            products.map((product, i) => {
              return (
                <ProductCard
                  key={i}
                  data={product}
                ></ProductCard>
              );
            })
          }
        </Banner>
      }

      {
        fetchingProducts && props.type !== 'slider' && <div className="product-list-skeleton">
          {
            Array.from(Array(4)).map((n, i) => {
              return (
                <ProductCardSkeleton key={i}></ProductCardSkeleton>
              );
            })
          }
        </div>
      }

      {
        fetchingProducts && props.type === 'slider' && <Banner>
          {
            Array.from(Array(4)).map((n, i) => {
              return (
                <ProductCardSkeleton key={i}></ProductCardSkeleton>
              );
            })
          }
        </Banner>
      }
    </div>
  );
};

export default CategoryGrid;
